<template>
  <v-card rounded="lg">
    <v-card-title> {{ site_data.name }} CMS </v-card-title>

    <v-card-text>
      <div class="d-flex">
        <!-- <v-btn color="primary" outlined rounded @click="logout">
          Logout
        </v-btn> -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      site_data: state => state.company.site_data
    }),
  },
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  methods: {
    // logout() {
    //   this.$store.dispatch("logout").then(() => {
    //     this.$router.push("/");
    //   });
    // },
  },
};
</script>

<style></style>