<template>
  <guest v-if="!$store.getters.isLoggedIn" />
  <auth v-else />
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    auth: require("./views/_layouts/Auth").default,
    guest: require("./views/_layouts/Guest").default,
  },
  computed: {
    ...mapState({
      site_data: state => state.company.site_data
    }),
  },
  beforeMount() {

    this.$vuetify.theme.themes.light.primary = `#183e0d`

    // change title with site_data.name
    document.title = this.site_data.name

    // change favicon whit site_date.favicon.url
    const favicon = document.querySelector('link[rel="icon"]')
    favicon.href = this.site_data.favicon.url
  },
};
</script>
<style>
@import "assets/css/style.css";
</style>
<style lang="scss">
@for $z from 1 through 24 {
  .v-application .flat-b-#{$z} {
    box-shadow: 0 #{$z * 2}px 0 rgba($color: #000000, $alpha: 0.1) !important;
  }

  .v-application .flat-l-#{$z} {
    box-shadow: #{$z * 2}px 0 0 rgba($color: #000000, $alpha: 0.1) !important;
  }

  .v-application .flat-d-#{$z} {
    box-shadow: #{$z*2}px #{$z*2}px 0 rgba($color: #000000, $alpha: 0.1) !important;
  }

  $z: $z - 1;
}

// border spacing on table
@for $i from 1 through 24 {
  .v-application .spacing-a-#{$i}>.v-data-table__wrapper>table {
    border-spacing: $i * 2px !important;
  }

  .v-application .spacing-y-#{$i}>.v-data-table__wrapper>table {
    border-spacing: 0 $i * 2px !important;
  }

  .v-application .spacing-x-#{$i}>.v-data-table__wrapper>table {
    border-spacing: $i * 2px 0 !important;
  }
}

html {
  overflow: auto;
}

.theme--light.v-btn,
.theme--light.v-btn.v-btn--icon,
.theme--light.v-icon {
  color: var(--v-color-base);
}

.theme--light.v-select .v-select__selection--comma,
.theme--light.v-data-table,
.theme--light.v-input input,
.theme--light.v-input textarea,
.theme--light.v-input,
.theme--light.v-expansion-panels .v-expansion-panel,
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.theme--light.v-application,
.theme--light.v-card>.v-card__text,
.theme--light.v-card .v-card__subtitle,
.theme--light.v-sheet {
  color: var(--v-color-base) !important;
}

.v-input--checkbox .v-icon {
  color: #9cafc6;
}

.theme--light.v-application {
  background-color: var(--v-background-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-up {
  from {
    width: 100%;
    position: absolute;
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    width: 100%;
    position: absolute;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-enter-active {
  animation: fade 0.3s;
}

.fade-up-leave-active {
  animation: fade-up 0.3s reverse;
}

.fade-up-enter-active {
  animation: fade-up 0.3s;
}

a {
  text-decoration: none;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) .v-badge__badge {
  color: #fff !important;
  background-color: #9cafc6 !important;
}

.table-fixed table,
.table-fixed.table {
  table-layout: fixed;
  overflow-wrap: break-word;
  width: 100%;
}

.table-fixed th:first-child,
.table-fixed th:last-child,
.table-fixed td:first-child,
.table-fixed td:last-child {
  width: 10%;
}

.table-fixed th:first-child,
.table-fixed td:first-child {
  padding-left: 32px !important;
}

.table-fixed th:last-child,
.table-fixed td:last-child {
  padding-right: 32px !important;
}

.color-navy {
  color: #4c6583 !important;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled,
.color-navy-soft {
  color: #9cafc6 !important;
}

.v-tab.v-tab:not(:last-child) {
  margin-right: 20px;
}

.v-expansion-panel::before {
  content: none !important;
}

.accent-warning {
  color: #be9f05 !important;
  border: 1px solid #be9f05 !important;
  background: transparent !important;
}

.accent-success {
  color: #4da41d !important;
  border: 1px solid #4da41d !important;
  background: transparent !important;
}

.accent-default {
  color: #9cafc6 !important;
  border: 1px solid #9cafc6 !important;
  background: transparent !important;
}

.theme--light.v-tabs>.v-tabs-bar {
  background-color: transparent !important;
}

.tabku.theme--dark.v-tabs>.v-tabs-bar {
  background-color: transparent;
}
</style>