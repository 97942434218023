export default [
  { title: "Dashboard", icon: "$dashboard", link: "/dashboard" , permission: 'Dashboard'},
  { title: "Kelola Program", icon: "$program", link: "/program", permission: 'Program' },
  { title: "Data Detail Test", icon: "ri-gallery-line", link: "/results", permission: 'Results'},
  { title: "Trainer", icon: "ri-team-line", link: "/trainer", permission: 'Trainer' },
  // { title: "Reseller", icon: "ri-user-line", link: "/daftar-reseller", permission: 'Reseller' },
  { title: "Notifikasi", icon: "ri-chat-settings-line", link: "/notifikasi", permission: 'Notification' },
  // { title: "Presensi", icon: "ri-list-check-2", link: "/presensi", permission: 'Presensi' },
  // { title: "Import Nilai UK", icon: "ri-upload-cloud-2-line", link: "/import", permission: 'Import' },
  { title: "Import Nilai TPM", icon: "ri-upload-cloud-2-line", link: "/import-feedback", permission: 'Import' },
  // { title: "Sertifikat", icon: "ri-award-fill", link: "/update-content" },
  // { title: "Log Redeem", icon: "ri-settings-3-line", link: "/redeem-log", permission: 'Logs' },
];
