<template>
  <v-row align-content="center" class="white">
    <v-col class="py-0 mx-auto" md="7">
      <v-row>
        <v-col class="px-12 white" md="11" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <v-form ref="form" class="px-8" @submit.prevent="submit" v-model="valid">
            <div class="mx-auto mb-2 text-center">
              <img :src="header_logo" alt="Logo" class="me-2" style="max-width: 250px;">
            </div>
            <v-row>
              <v-col>
                <div class="mb-2 subtitle-2">Email</div>
                <v-text-field filled v-model="email" placeholder="Masukkan email"
                  :rules="[...rules('Email', 'required'), validate]" required outlined
                  hide-details="auto"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div class="mb-2">
                  <span class="subtitle-2">Password</span>
                </div>
                <v-text-field filled v-model="password" placeholder="Masukkan password"
                  :type="show ? 'text' : 'password'" :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show" outlined hide-details="auto"
                  :rules="rules('Password', 'required')"></v-text-field>
              </v-col>
            </v-row>
            <v-spacer class="py-2"></v-spacer>
            <v-row>
              <v-col>
                <v-btn large elevation="0" type="submit" color="primary" :loading="submiting" block>
                  Login
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { AbilityBuilder } from "@casl/ability";
import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      valid: true,
      errors: null,
      email: "",
      password: "",
      submiting: false,
      show: false
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
    ...mapState({
      site_data: state => state.company.site_data
    }),
    header_logo() {
      return this.site_data.logo?.url;
    },
  },
  methods: {
    updateAbility(user) {
      const { can, rules } = new AbilityBuilder();

      if (user.role === 'superadmin') {
        can('manage', 'all');
      } else {
        can('create', 'Program');
      }

      this.$ability.update(rules);
    },
    submit() {
      this.errors = null;

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          let email = this.email;
          let password = this.password;

          this.$store
            .dispatch("login", { email, password })
            .then((user) => {

              this.submiting = false;

              this.updateAbility({
                role: user?.role?.key || "superadmin"
              });

              // if (sessionStorage.getItem("ytc_redirect") != null) {
              //     this.$router.push(sessionStorage.getItem("ytc_redirect"));
              //     sessionStorage.removeItem("ytc_redirect");
              // } else {
              //     this.$router.push("/dashboard");
              // }
              this.$router.push("/dashboard");
            })
            .catch((error) => {
              this.submiting = false;
              this.errors = error.response.data;
            });
        }
      }, 50);
    },
  },
};
</script>

<style></style>