<template>
  <v-row>
    <v-col cols="12">
      <div class="font-weight-bold">Pengaturan</div>
    </v-col>
    <v-col cols="12">
      <div class="font-weight-medium">
        <span> Estimasi Durasi (Menit)</span>
      </div>
      <v-row no-gutters>
        <v-col cols="12" md="7">
          <v-row>
            <v-col>
              <v-text-field v-model="data.estimated_duration" type="number" min="0" placeholder="0"></v-text-field>
            </v-col>
            <!-- <v-col>
              <v-text-field v-model="data.estimated_duration" type="number" min="0" placeholder="0"></v-text-field>
              <div class="text--disabled">Menit</div>
            </v-col>
            <v-col>
              <v-text-field v-model="data.estimated_duration" type="number" min="0" placeholder="0"></v-text-field>
              <div class="text--disabled">Detik</div>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-col cols="12" class="px-0 py-0">
        <div class="font-weight-bold">Durasi Pengumpulan</div>
        <div class="mb-3 font-weight-normal subtitle-2 grey--text">Pengaturan durasi button Pilih Berkas dalam format hari</div>
        <v-text-field
          placeholder="Silahkan isikan berapa hari durasi pengumpulan berkas EPM di atur"
          v-model.number="data.max_upload_duration_evaluasi_praktek_mandiri">
        </v-text-field>
        <div class="primary--text" style="font-size: 10px;">*Default pengumpulan EPM 7 hari setelah mengakses Praktek Mandiri </div>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import model from "../_mixins/model";
export default {
  mixins: [model],
  created() {
    this.$set(this.data, "opportunity_repeat_audio", this.data.opportunity_repeat_audio);
  },
};
</script>